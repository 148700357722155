import 'src/css/styles'
const images = require.context('../src/img', true)
const fonts = require.context('../src/fonts', true)

import jquery from 'jquery'

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import moment from 'moment'
import 'jquery.cookie'
import 'sticky-kit/dist/sticky-kit'
import Swal from 'sweetalert2'
import Swiper, { Navigation, Thumbs } from 'swiper'
import 'owl.carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'jquery.maskedinput/src/jquery.maskedinput';
import HystModal from 'src/js/hystmodal'
import 'src/js/jquery.lockfixed'
import 'src/js/script'

global.moment = moment;
global.HystModal = HystModal;
global.Swiper = Swiper;
global.Swal = Swal;
Swiper.use([Navigation, Thumbs]);

moment.locale('ru');