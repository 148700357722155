$(document).ready(function() {
  initializeDonationForm();
  prepareDonationForm();

  let galleryThumbs = new Swiper('.slider-thumbs', {
    spaceBetween: 20,
    slidesPerView: 3,
    watchSlidesVisibility: true,
    loop: true,
    direction: 'vertical',
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    watchSlidesProgress: true,
    breakpoints: {
      0: {
        direction: 'horizontal'
      },
      481: {
        spaceBetween: 15,
        direction: 'vertical'
      }
    }
  });

  let galleryTop = new Swiper('.slider-main', {
    slidesPerView: 1,
    direction: 'vertical',
    loop: true,
    thumbs: {
      swiper: galleryThumbs
    },
    breakpoints: {
      0: {
        direction: 'horizontal'
      },
      481: {
        direction: 'vertical'
      }
    }
  });

  $("#new_subscription").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);

    $.ajax({
      url: url,
      type: "POST",
      dataType: "json",
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      success: function (data) {
        if (data.saved) {
          swalSuccess();
          $("#new_subscription")[0].reset();
        }
        else {
          swalErrors(data.errors);
        }
      },
      error: function (data) {
        swalInternalError();
      }
    });
    executeRecaptchaForNewSubscription();
  });

  $(".feedback-form").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);
    $.ajax({
      url: url,
      type: "POST",
      dataType: "json",
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      success: function (data) {
        if (data.saved) {
          swalSuccess();
          $(".feedback-form")[0].reset();
          $('#form_call').removeClass('hystmodal--active');
          $('.hystmodal__shadow').removeClass('hystmodal__shadow--show');
        }
        else {
          swalErrors(data.errors);
        }
      },
      error: function (data) {
        swalInternalError();
      }
    });
    executeRecaptchaForSaveFeedback();
  });

  $("#new_volunteer").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);

    $.ajax({
      url: url,
      type: "POST",
      dataType: "json",
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      success: function (data) {
        if (data.saved) {
          swalSuccess();
          $("#new_volunteer")[0].reset();
        }
        else {
          swalErrors(data.errors);
        }
      },
      error: function (data) {
        swalInternalError();
      }
    });
    executeRecaptchaForSaveVolunteer();
  });

  $("#new_cooperation_request").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);

    $.ajax({
      url: url,
      type: "POST",
      dataType: "json",
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      success: function (data) {
        if (data.saved) {
          swalSuccess();
          $("#new_cooperation_request")[0].reset();
        }
        else {
          swalErrors(data.errors);
        }
      },
      error: function (data) {
        swalInternalError();
      }
    });
    executeRecaptchaForSaveCooperationRequest();
  });

  $("#new_request").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);

    $.ajax({
      url: url,
      type: "POST",
      dataType: "json",
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      success: function (data) {
        if (data.saved) {
          swalSuccess();
          $("#new_request")[0].reset();
        }
        else {
          swalErrors(data.errors);
        }
      },
      error: function (data) {
        swalInternalError();
      }
    });
    executeRecaptchaForSaveHelpRequest();
  });

  checkCookies();

  $('.mobile-nav__drop').slideUp();
  $('.help-radio-label:last').css('border-right', '1px solid #1C9DC3');

  // Карусель фотографий
  $('.owl-carousel').owlCarousel({
    loop: false,
    margin: 25,
    dots: true,
    autoplay: true,
    nav: false,
    responsive: {
      0: {items: 1},
      600: {items: 3},
      1000: {items: 3}
    }
  });

  // Меню для мобильных устройств
  $('.burger-wrap').on('click', function() {
    if ($('.hamburger').attr('data-click') === '0') {
      $('.line-mid').css('display', 'none');
      $('.line-top').css({ 'top': '6px', 'transform': 'rotate(45deg)' });
      $('.line-bottom').css({ 'top': '-9px', 'transform': 'rotate(-45deg)' });
      $('.burger-wrap').css('background', '#fff');
      $('.hamburger__line').css('background-color', '#DF6682');
      $('.header-mob-drop').show(200);
      $('.hamburger').attr('data-click', '1');
      $('header').css({ 'background-color': '#fff', 'box-shadow': 'unset' });
      $('.header__bottom').css('display', 'none');
      $('body').css('overflow-y', 'hidden');
      $('.header-help__mob-link').css('display', 'none');
    } else if ($('.hamburger').attr('data-click') === '1') {
      $('.line-mid').css('display', 'block');
      $('.line-top').css({ 'top': '0', 'transform': 'rotate(0)' });
      $('.line-bottom').css({ 'top': '0', 'transform': 'rotate(-0)' });
      $('.hamburger__line').css('background-color', '#fff');
      $('.burger-wrap').css('background', '#DF6682');
      $('.header-mob-drop').hide(200);
      $('.hamburger').attr('data-click', '0');
      $('header').css({ 'box-shadow': 'rgba(0, 0, 0, 0.13) 0px 4px 4px' });
      $('.header__bottom').css('display', 'flex');
      $('body').css('overflow-y', 'unset');
      $('.header-help__mob-link').css('display', 'flex');
    }
  });

  // Липкий блок на странице ребенка
  if ($('.c-detail__info-wrap').length) {
    let stickyEl = $('.c-detail__info-wrap');
    $('div.wrapper').css('overflow', 'unset');
  }

  // Открытие поиска
  let headerSearchInput = $('.header-search');
  let searchSvg = $('.search__button-svg');
  headerSearchInput.on('click', function() {
    headerSearchInput.toggleClass('active');
    searchSvg.toggleClass('active');
  });

  // Раскрытие пунктов в мобильном меню
  let mobileDropButton = $('.mobile-nav-arrow');
  mobileDropButton.on('click', function(e) {
    $(this).parent('div').siblings('.mobile-nav__drop').slideToggle();
  });

  // Закрытие модального окна при клике вне его
  $('.n-p__layer-sec').on('click', function() {
    $('.n-p__final-form.pozh').hide();
    $('.n-p__final-form').removeClass('active');
    $('.n-p__layer-sec').removeClass('active');
  })

  // В блоке помогать легко селектор суммы
  let valueLabels = $('.help-radio-label').toArray();
  let helpInput = $('.donation-amount');
  $.each(valueLabels, (key, value) => {
    value.addEventListener('click', () => {
      let choosenValue = value.querySelector('.help-radio-hidden').value;
      $(helpInput).val(choosenValue);
    });
  });

  // Помогать легко ввод собственной суммы
  $('.about-form-summary').on('click', function () {
    $('.about-form__input').toggleClass('active');
  });

  // Форма оплаты помогать легко
  $('.sum-donation-main-button').on('click', function (e) {
    let form = $(this).closest('form');
    let errors = false;

    if (form.find('.public_offer').length > 0 && form.find('.public_offer').is(":checked") == false) {
      errors = true;
      swalErrorOffer();
    }
    if (errors == false) {
      $(this).closest('form').find('.n-p__final-form').toggleClass('active');
      $(this).closest('form').find('.n-p__layer-sec').toggleClass('active');
    }
  })

  // Открывает форму пожертвования на главной в шапке
  $('.form_in_pozhertvovaniya2 .button--big').on('click', function (e) {
    // if ($.cookie('direct_donate_form')) {
    //   let authenticityToken = $('#authenticity_token').val();
    //   let url = '/donate';
    //   let formData = new FormData();
    //   formData.append('authenticity_token', authenticityToken);
    //   formData.append('direct_donate_form', true);
    //   formData.append('donation[amount]', $.cookie('amount'));
    //   formData.append('donation[recurrent]', true);
    //   formData.append('donation[tag]', $.cookie('tag'));
    //   formData.append('donation[fundraiser]', $.cookie('fundraiser'));
    //   formData.append('donation[payment_method]', $.cookie('payment_method'));
    //   callDonateForm(url, formData);
    // }
    // else {
      $('.form_in_pozhertvovaniya2 .n-p__final-form').toggleClass('active');
      $('.form_in_pozhertvovaniya2 .n-p__layer-sec').toggleClass('active');
    // }
  })

  $('a.donation-page-link').on('click', function (e) {
    window.location.href = $(this).data('link');
  })

  // Инициируем модальные окна
  global.myModal = new HystModal({
    catchFocus: true,
    closeOnEsc: true,
    backscroll: true,
    beforeOpen: function(modal){
    },
    afterClose: function(modal){
      let videoframe = modal.openedWindow.querySelector('iframe');
      if(videoframe){
        videoframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
      }
    }
  });

  // Добавление анимации на главной странице
  let bg = document.querySelector('.mouse-parallax-bg');
  if(bg) {
    window.addEventListener('mousemove', function (e) {
      let x = e.clientX / window.innerWidth;
      let y = e.clientY / window.innerHeight;
      bg.style.transform = 'translate(-' + x * 20 + 'px, -' + y * 0 + 'px)';
    });
  }
  let bg_1 = document.querySelector('.main__program__bg_2');
  if (bg_1) {
    window.addEventListener('mousemove', function (e) {
      let x = e.clientX / window.innerWidth;
      let y = e.clientY / window.innerHeight;
      bg_1.style.transform = 'translate(-' + x * 20 + 'px, -' + y * 20 + 'px)';
    });
  }
});

global.directDonation = function directDonation(selected) {
  let formData = new FormData();
  let url = $(selected).data('url');
  let amount = $(selected).data('amount');
  let tag = $(selected).data('tag');
  let fundraiser = $(selected).data('fundraiser');
  let paymentMethod = $(selected).data('payment-method');
  let fundraisingId = selected ? ($(selected).data('fundraising') || []) : [];
  let authenticityToken = $(selected).data('token');

  formData.append('authenticity_token', authenticityToken);
  formData.append('direct_donate_form', true);
  formData.append('donation[fundraising_id]', fundraisingId);
  formData.append('donation[amount]', amount);
  formData.append('donation[tag]', tag);
  formData.append('donation[fundraiser]', fundraiser);
  formData.append('donation[payment_method]', paymentMethod);
  callDonateForm(url, formData);
}

global.openDonationModal = function openDonationModal(selected) {
  let url = $(selected).data('url');

  $.ajax({
    url: url,
    type: "GET",
    dataType: "html",
    success: function (data) {
      $('.donation-modal-full .donation-modal-body').html(data);
      $('.n-p__layer').addClass('active');
      initializeDonationForm();
      prepareDonationForm();
      myModal.open('.donation-modal-full');
    }
  });
}

global.prepareDonationForm = function prepareDonationForm() {
  let tag = $.cookie('FAND');
  let fundraiser = $.cookie('fundraiser');
  let amount = $.cookie('amount') || 0;

  $('.donate-form #donation_tag').val(tag);
  $('.donate-form #donation_fundraiser').val(fundraiser);
  $('.donate-form .total-donation-amount').html(amount);
  $('.donate-form .donation-amount').html(amount);
  $('.donate-form .payment_amount').val(amount);

  if ($.cookie('direct_donate_form')) {
    let payment_methods = $.cookie('payment_method') ? $.cookie('payment_method').split(',') : [];

    $('.donate-form ul.donation-modal-tabs').addClass('hidden');
    $('.donate-form h3.donation-title').addClass('hidden');
    $('.donate-form .donation-text').addClass('hidden');
    $('.donate-form .n-p__final h2.itog_p').addClass('hidden');
    $('.donate-form .n-p__list').addClass('hidden');
    $('.donate-form a.n-p__list-more').addClass('hidden');
    $('.donate-form .public_offer_container').addClass('hidden');
    $('.donate-form .subs_container').addClass('hidden');
    $('.donate-form .n-p__final a.n-p__submit').css('margin-top', '60px');
    if ($.cookie('payment_method').split(',').length < 2) {
      $('.n-p__tabs-types').addClass('hidden')
      $('.n-p__some.n-p__some-right h2').text('Введите сумму пожертвования')
    }
    $(`.donate-form #donation_payment_method_${payment_methods[0]}`).click();
    let all_payment_methods = ['sberbank', 'sbp', 'bank_card', 'yandex_money', 'qiwi', 'tinkoff_bank', 'alfabank', 'mobile', 'b2b_sberbank', 'cloudpayments'];
    let hidden_methods = all_payment_methods.filter(n => !payment_methods.includes(n))
    $(hidden_methods).each(function() {
      $(`.${this}-payment`).addClass('hidden');
    });
  }
}

global.initializeDonationForm = function initializeDonationForm() {
  $(".phone_mask").mask("+9(999)999-99-99");

  // Выделение карточки ребенка при выборе
  $('.checkbox-move').on('click', function () {
    $(this).parent('.n-p__some__desc').parent('.n-p__some').toggleClass('active');
  });

  // Закрытие модальных окон
  $('.n-p__final-close').on('click', function () {
    $('.n-p__final-form').removeClass('active');
    $('.n-p__layer-sec').removeClass('active');
  });
  $('.n-p__close').on('click', function () {
    $('.n-p__layer').removeClass('active');
  });

  //табы видов оплаты
  $('.n-p__tabs ul').on('click', 'li:not(.active)', function() {
    $(this).addClass('active').siblings().removeClass('active').closest('div.n-p__tabs').find('div.n-p__tabs-content').removeClass('active').eq($(this).index()).addClass('active');
  });

  //табы кастомной реферальной ссылки
  $('.n-p__tabs_referal ul').on('click', 'li:not(.active)', function() {
    $(this).addClass('active').siblings().removeClass('active').closest('div.n-p__tabs_referal').find('div.n-p__tabs_referal-content').removeClass('active').eq($(this).index()).addClass('active');
  });

  // Выбор ребенка на странице пожертвования
  $('.checkbox-form-move').on('change', function() {
    let form = $(this).closest('form');
    calc_summary(form);
  });

  // Изменение суммы пожертвования
  $('.payment_amount').on('change', function() {
    let form = $(this).closest('form');
    form.find('.veof span').html($(this).val() || 0);
    calc_summary(form);
  });
  $('.payment_amount').on('keyup', function() {
    let form = $(this).closest('form');
    form.find('.veof span').html($(this).val() || 0);
    calc_summary(form);
  });

  // Клик на кнопку отображения других детей на странице пожертвования
  $('.n-p__list-more').on('click', function () {
    $('.n-p__some').toggleClass('hiddens');
  });

  // Открытие формы на странице пожертвования для ввода телефона и почты
  $('.n-p__submit').on('click', function () {
    let form = $(this).closest('form');
    let errors = false;
    if ($.cookie('direct_donate_form')) {
      let url = form.attr('action');
      let formData = new FormData(form[0]);
      callDonateForm(url, formData)
    } else {
      if (form.find('.public_offer').length > 0 && form.find('.public_offer').is(":checked") == false) {
        errors = true;
        swalErrorOffer();
      }
      if (form.find('.payment_amount').val() <= 0 ) {
        errors = true
        swalErrorAmount();
      }

      if (errors == false) {
        $('.n-p__final-form.pozh').toggleClass('active');
        $('.n-p__layer-sec.pozh').toggleClass('active');
      }
    }
  });

  // Обработка формы пожертвования
  $(".donate-form").on('submit', async function(e) {
    event.preventDefault();

    // VK event
    _tmr.push({ type: 'reachGoal', id: 3369926, value: 1, goal: 'donation'});

    // let donate_recaptcha = await executeRecaptchaForDonateAsync();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);
    let errors = false;
    let email = formData.get('donation[email]')

    if (formData.get('donation[payment_method]') == 'mobile') {
      if (formData.get('donation[phone]') === '') {
        swalErrorPhone();
        errors = true
      }
    } else {
      if (email == '' || emailCheck(email) == null) {
        swalErrorMail();
        errors = true
      }
    }

    if (errors == false) {
      // formData.append('g-recaptcha-response-data[donate]', donate_recaptcha);
      callDonateForm(url, formData);
    }
  });
}

global.callDonateForm = function callDonateForm(url, formData) {
  let amount = formData.get('donation[amount]')

  if (amount == '' || amount == 0) {
    swalErrorAmount();
  } else {
    $.ajax({
      url: url,
      type: "POST",
      dataType: "json",
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      success: function (data) {
        if (data.saved) {
          try {
            // VK event
            VK.Goal('lead');

            // Yandex commerce event
            dataLayer.push({
              "ecommerce": {
                "currencyCode": "RUB",
                "purchase": {
                  "products": [
                    {
                      "name": data.description,
                      "price": data.amount,
                      "brand": "БФ Ксения Спасает Жизни",
                      "quantity": 1,
                      "list": "Donation"
                    }
                  ]
                }
              }
            });

          } catch {
            // ToDo remove this later
            // just ignore
          }
          if (data.payment_gateway == 'uniteller') {
            $('#unitellerPay input[name=Order_IDP]').val(data.payment_id);
            $('#unitellerPay input[name=Subtotal_P]').val(data.amount);
            $('#unitellerPay input[name=Signature]').val(data.signature);
            $('#unitellerPay input[name=Email]').val(data.email);
            $('#unitellerPay input[name=Phone]').val(data.phone);
            $('#unitellerPay input[name=IsRecurrentStart]').val(data.recurrent);
            $("#unitellerPay").submit();
          } else if (data.payment_gateway == 'yookassa') {
            window.location.href = data.payment_url;
          } else if (data.payment_gateway == 'robokassa') {
            window.location.href = data.payment_url;
          } else if (data.payment_gateway == 'mixplat') {
            window.location.href = data.payment_url;
          } else if (data.payment_gateway == 'cloudpayments') {
            cloudPaymentPay(data.public_key, data.email, data.payment_id, data.description, data.amount, data.currency)
          } else if (data.payment_gateway == 'qiwi_gate') {
            window.location.href = data.payment_url
          } else {
            swalInternalError();
          }
        }
        else {
          swalErrors(data.errors);
        }
      },
      error: function (data) {
        swalInternalError();
      }
    });
  }
}

// Проверка использованиия Cookie
function checkCookies() {
  let use_cookies = $.cookie('use_cookies');
  let cookieNotification = document.getElementById('cookie_notification');
  let cookieBtn = cookieNotification.querySelector('.cookie_accept');
  if (!use_cookies) {
    cookieNotification.classList.add('show');
    cookieBtn.addEventListener('click', function() {
      $.cookie('use_cookies', true);
      cookieNotification.classList.remove('show');
    });
  }
}

$(window).scroll(function(e) {
  if ($(this).scrollTop() > 18) {
    $('.header__top').css('display', 'none');
    $('header').css({
      'background-color': '#fff',
      'box-shadow': '0px 4px 4px rgba(0, 0, 0, 0.13)',
      'height': '68px'
    });
    $('.header-nav-topside').css('height', '50px');
    $('.header__logo img').addClass('scrolling');
    $('.header__logo-text').addClass('scrolling');
    $('.header__right').addClass('scrolling');
    $('.header__bottom').addClass('scrolling');
    $('.search__button').css('display', 'none');
    $('.header-help-btn').css('margin-left', '25px');
  } else if ($(this).scrollTop() < 114) {
    $('.header-nav-topside').css('height', '111px');
    $('.header__top').css('display', 'flex');
    $('header').css({ 'background-color': 'unset', 'box-shadow': 'unset', 'height': 'unset' });
    $('.header__logo-text').removeClass('scrolling');
    $('.header__right').removeClass('scrolling');
    $('.header__logo img').removeClass('scrolling');
    $('.header__bottom').removeClass('scrolling');
    $('.search__button').css('display', 'flex');
    $('.header-help-btn').css('margin-left', '0');
  }
});

if ($(window).scrollTop() > 18) {
  $('.header__top').css('display', 'none');
  $('header').css({ 'background-color': '#fff', 'box-shadow': '0px 4px 4px rgba(0, 0, 0, 0.13)', 'height': '68px' });
  $('.header-nav-topside').css('height', '50px');
  $('.header__logo img').addClass('scrolling');
  $('.header__logo-text').addClass('scrolling');
  $('.header__right').addClass('scrolling');
  $('.header__bottom').addClass('scrolling');
  $('.search__button').css('display', 'none');
  $('.header-help-btn').css('margin-left', '25px');
}

global.swalSuccess = function swalSuccess() {
  Swal.fire({
    title: 'Сообщение отправлено',
    type: 'success',
    showConfirmButton: false,
    html: '<p style="color: #004c75;">Мы скоро свяжемся с Вами.</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC" /><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC" /></svg></button>'
  });
  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
}

global.swalInternalError = function swalInternalError() {
  Swal.fire({
    title: 'Сообщение не отправлено',
    type: 'success',
    showConfirmButton: false,
    html: '<p style="color: #004c75;">В ближайшее время мы исправим данную проблему</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC" /><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC" /></svg></button>'
  });
  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
}

global.swalErrors = function swalErrors(errors) {
  let html = '<p style="color: #004c75;">Исправьте следующие ошибки и отправьте повторно:</p><ol>'
  $(errors).each(function() {
    html += `<li>${this}</li>`
  });
  html += '</ol><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC" /><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button></button>'
  Swal.fire({
    title: 'Сообщение не отправлено',
    type: 'success',
    showConfirmButton: false,
    html: html
  });
  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
}

global.swalErrorMail = function swalErrorMail() {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: false,
    html: '<p style="color: #004c75;">Пожалуйста, введите корректный e-mail</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC" /><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC" /></svg></button>'
  });
  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
}

global.swalErrorOffer = function swalErrorOffer() {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: false,
    html: '<p style="color: #004c75;">Необходимо подтвердить согласие с публичной офертой и условиями на обработку персональных данных</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC" /><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC" /></svg></button>'
  });
  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
}

global.swalErrorPhone = function swalErrorPhone() {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: false,
    html: '<p style="color: #004c75;">Пожалуйста, введите корректный телефон</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC" /><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC" /> </svg></button>'
  });
  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
}

global.swalErrorAmount = function swalErrorAmount() {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: false,
    html: '<p style="color: #004c75;">Пожалуйста, введите сумму пожертвования</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC" /><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC" /></svg></button>'
  });
  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
}
// Копирование текста(фандрайзер)
global.copytext = function copytext(el) {
  let $tmp = $("<input>");
  $("body").append($tmp);
  $tmp.val($(el).text()).select();
  document.execCommand("copy");
  $tmp.remove();
  $('#modal_fand').removeClass('hystmodal--active');
  $('.hystmodal__shadow').removeClass('hystmodal__shadow--show');
}

// Расчет итоговой суммы пожертвования
global.calc_summary = function calc_summary(form) {
  $(form).find('.itog_p b').html('0');
  let count = 1;
  $(form).find('.checkbox-form-move').each(function() {
    if ($(this).is(':checked')) {
      count = count + 1;
    }
  })
  let val = $(form).find('.payment_amount').val();
  $(form).find('.itog_p b').html(parseInt(val) * count);
}

// Проверка корректности ввода почты
global.emailCheck = function emailCheck (email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function cloudPaymentPay(public_key, email, payment_id, desc, amount, currency) {
  $('.n-p__final-form').removeClass('active');
  $('.n-p__layer-sec').removeClass('active');
  $('.n-p__layer').removeClass('active');
  let widget = new cp.CloudPayments();
  widget.pay('auth',
    {
      publicId: public_key,
      description: desc,
      amount: amount,
      currency: currency,
      accountId: email,
      invoiceId: payment_id,
      email: email,
      skin: "mini",
      autoClose: 3,
      data: {
        myProp: ''
      }
    },
    {
      onSuccess: function (options) {
        window.location.href = '/success-pay';
      },
      onFail: function (reason, options) {
        window.location.href = '/error_pay';
      }
    }
  )
}